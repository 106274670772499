<template>
	<router-view />
</template>

<script>
export default {
	name: 'App',
};
</script>

<style lang="scss">
@import "./scss/base";
</style>
